import { Navbar } from '@common/ui/navigation/navbar/navbar';
import NewFooter from '@common/ui/new-footer/NewFooter';
import React, { useState } from 'react';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'What payment methods do you accept?',
      answer: 'We accept all major credit cards and PayPal.'
    },
    {
      question: 'How can I track my order?',
      answer: 'You can track your order using our website.'
    },
    {
      question: 'Do you offer refunds?',
      answer: 'Yes, we offer refunds within 30 days of purchase.'
    },
    // Add more FAQs as needed
  ];

  return (
    <>
      <Navbar
        color="transparent"
        className="flex-shrink-0"
        menuPosition="homepage-navbar"
      />
      <div className="container mx-auto p-4 my-50 mb-[100px]">
        <div className="flex justify-center">
          <div className="w-full md:w-1/2">
            <h1 className="text-3xl text-center font-semibold mb-50">Frequently Asked Questions</h1>
            <div className="space-y-4">
              {faqs.map((faq, index) => (
                <div key={index} className="border border-gray-300 rounded-md px-5">
                  <div
                    className="flex justify-between items-center p-4 cursor-pointer"
                    onClick={() => toggleFaq(index)}
                  >
                    <h2 className="text-xl font-semibold text-[#363636]">{faq.question}</h2>
                    <svg
                      className={`w-20 h-20 transform transition-transform duration-300 ${activeIndex === index ? 'rotate-180' : ''
                        }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                  {activeIndex === index && (
                    <div className="p-4 border-t border-gray-300">
                      <p className="text-gray-700 text-sm text-[#475569]">{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <NewFooter />
    </>
  );
};

export default Faq;
