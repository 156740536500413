import { FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import "../../../../../resources/client/css/landingPage.css"
import { Link } from "react-router-dom";
const NewFooter = () => {
  return (
    <footer className="footer-1 py-[60px] gradient-bg">
    <div className="container mx-auto py-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="col-span-1 md:col-span-1 lg:col-span-1 mb-8 md:mb-0">
          <Link to="#" className="block mb-4">
            <img src="storage/branding_media/c4eba1c8-37c5-4a75-ae75-da419d048f57.png" alt="logo" width="150" height="40" className="img-fluid" />
          </Link>
          <p className="text-gray-700 mt-20">
            MyDocUploads is a file storage and document collaboration platform that helps teams stay safe, secure and connected in the cloud.
          </p>
          <ul className="list-inline social-list-default background-color social-hover-2 mt-20   flex">
            <li className="list-inline-item">
              <a className="twitter" target="_blank" href="#">
                <FaTwitter className="fab fa-twitter"/>
                </a>
            </li>
            <li className="list-inline-item">
              <a className="youtube" target="_blank" href="#">
                <FaYoutube className="fab fa-youtube" />
                </a>
            </li>
            <li className="list-inline-item">
              <a className="linkedin" target="_blank" href="#">
                <FaLinkedin className="fab fa-linkedin-in" />
                </a>
            </li>
            <li className="list-inline-item">
              <a className="instagram" target="_blank" href="#">
                <FaInstagram className="fab fa-instagram"/>
                </a>
            </li>
          </ul>
        </div>
        <div className="col-span-1 md:col-span-1 lg:col-span-1">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h6 className="font-weight-normal">Resources</h6>
              <ul>
                <li><Link to="/pages/help">Help</Link></li>
                <li><Link to="/pages/terms-of-service">Terms of Service</Link></li>
                <li><Link to="/pages/security-privacy">Security & Privacy</Link></li>
                <li><Link to="/pages/about-us">About Us</Link></li>
                {/* <li><Link to="/api-docs">API Documentation</Link></li> */}
              </ul>
            </div>
            <div>
              <h6 className="font-weight-normal">Products</h6>
              <ul>
                <li><Link to="/pricing">Pricing</Link></li>
                <li><a href="blog">Blog</a></li>
                <li><Link to="#">AI Studio</Link></li>
                <li><Link to="#">Enterprise</Link></li>
                <li><Link to="#">Performance</Link></li>
              </ul>
            </div>

          </div>
        </div>
        <div className="col-span-1 md:col-span-1 lg:col-span-1">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">

            <div>
              <h6 className="font-weight-normal">Company</h6>
              <ul>
                <li><Link to="/pages/about-us">Mission</Link></li>
                <li><Link to="#">Careers</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="#">Community</Link></li>
                <li><Link to="/pages/about-us">Our Team</Link></li>
              </ul>
            </div>
            <div>
              <h6 className="font-weight-normal">Support</h6>
              <ul>
                <li><Link to="/pages/faq">FAQ</Link></li>
                <li><Link to="login">My Account</Link></li>
                <li><Link to="register">Register</Link></li>
                <li><Link to="/pages/solutions">Solutions</Link></li>
                <li><Link to="/pages/features">Features</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default NewFooter
