import { Footer } from '@common/ui/footer/footer';
import { Navbar } from '@common/ui/navigation/navbar/navbar'
import NewFooter from '@common/ui/new-footer/NewFooter';
import { Link } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
const Features = () => {
  return (
    <>
      <div className='container mx-auto'>
        <Navbar
          color="transparent"
          className="flex-shrink-0"
          menuPosition="homepage-navbar"
        />
        <FeaturesTabs />
      </div>
      <NewFooter />
    </>
  )
}

export default Features

function FeaturesTabs() {
  return (
    <section className="promo-section lg:py-[100px] ">
      <div className="container mx-auto">
        <div className="flex flex-col">
          <h1 className='lg:text-7xl md:text-[40px]  fw-[700] text-center'>Discover What You Can Do with Our Platform's <span className='text-[#014CDA]'>Powerful Features</span></h1>
        </div>
      </div>

      <Tabs className='features-tabs mt-20'>
        <TabList className='flex flex-col md:flex-row items-center md:items-center justify-center'>
          <Tab>Secure messaging</Tab>
          <Tab>Anywhere Access</Tab>
          <Tab>Secure Collaboration</Tab>
          <Tab>Professional File Transfers</Tab>
          <Tab>Security & Compliance</Tab>
        </TabList>

        <TabPanel>
          <SecureMessaging />
        </TabPanel>
        <TabPanel>
          <AnywhereAccess />
        </TabPanel>
        <TabPanel>
          <SecureCollaboration />
        </TabPanel>
        <TabPanel>
          <ProfessionalFileTransfers />
        </TabPanel>
        <TabPanel>
          <SecurityCompliance />
        </TabPanel>
      </Tabs>
    </section>
  );
}

function SecureMessaging() {
  return (
    <>
      <div className="grid md:grid-cols-2 gap-5 pt-50">
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
            Secure Message Service - Send <span className='text-[#014CDA]'>Encrypted Messages & Files</span> with Confidence
          </h1>
          <p className='text-sm lg:text-[#475569] mt-10'>In today's digital age, security and privacy are more critical than ever. At MyDocUploads, we understand the need to protect sensitive information, which is why we've developed our Secure Message service. This service allows you to send encrypted messages and file attachments to anyone, ensuring your data is safe and confidential.</p>
        </div>
        <div>
          <img src="./public/images/features/secure-messaging.svg" width="100%" alt="" />
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-5 lg:pt-50 item-center justify-center">
        <div>
          <img src="./public/images/features/feature-1.svg" width="100%" className='mt-20 md:mt-0' alt="" />
        </div>
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <p className='text-sm text-[#F67A3C]'>End-to-End Encryption</p>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
            Why Choose Our  <span className='text-[#014CDA]'>Secure Message</span> Service?
          </h1>
          <p className='text-sm lg:text-[#475569] mt-10'>Our Secure Message service uses advanced encryption technology to protect your messages and file attachments. This ensures that only the intended recipient can access the information, keeping your data safe from prying eyes.</p>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-5 pt-50 item-center justify-center">
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <p className='text-sm text-[#F67A3C]'>Simple and User-Friendly</p>
          <h1 className="lg:text-[40px] text-bold leading-[48px]">
            How It  <span className='text-[#014CDA]'>Works</span>?
          </h1>
          <p className='text-sm lg:text-[#475569] md:mt-10'>Sending a secure message with MyDocUploads is straightforward. Simply compose your message, attach any necessary files, and send it to your recipient. Our platform handles the encryption process automatically.</p>
        </div>
        <div>
          <img src="./public/images/features/how-work.svg" width="100%" className='mt-10' alt="" />
        </div>
      </div>

      <div className="grid grid-cols-1/2 gap-5 md:pt-50 item-center justify-center mt-50">
        <h1 className="text-[30px] lg:text-[40px] text-bold text-center leading-[48px]">
          Key <span className='text-[#014CDA]'>Features</span> of Secure Message
        </h1>
        <div className='relative md:mt-50'>
          <div className='md:absolute top-0 md:m-20 bg-white'>
            <div className='card p-10 md:w-[25rem] rounded-[15px] mb-20'>
              <h4 className='text-[#014CDA]'>Automatic Data Purge</h4>
              <p className='text-sm lg:text-[#475569] mt-2'>Sending a secure message with MyDocUploads is straightforward. Simply compose your message, attach any necessary files, and send it to your recipient. Our platform handles the encryption process automatically.</p>
            </div>
          </div>
          <img src="./public/images/features/message-feature.svg" width="100%" alt="" />
          <div className='md:absolute bottom-0 right-0 m-20 bg-white'>
            <div className='card p-10 md:w-[25rem] rounded-[15px]'>
              <h4 className='text-[#014CDA]'>Link Expiry Options</h4>
              <p className='text-sm lg:text-[#475569] mt-2'>Customize how long your link stays active. Choose from various expiry options to fit your needs, providing flexibility and added security.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-1/2 pt-50 item-center justify-center md:m-50">
        <h1 className="text-[30px] lg:text-[40px] text-bold text-center leading-[48px]">
          <span className='text-[#014CDA]'>Benefits</span> of Using Secure Message
        </h1>
        <div className="grid md:grid-cols-2 gap-30 md:pt-50 item-center justify-center">
          <div className='card p-20'>
            <h4 className='text-[#383838] text-bold text-[30px]'>Peace of Mind</h4>
            <p className='text-sm lg:text-[#475569] mt-10'>With our Secure Message service, you can rest assured that your sensitive information is protected. Whether you're sharing personal details, business documents, or confidential communications, our service keeps your data secure.</p>
            <img src="./public/images/features/peace-mind.svg" width="100%" alt="" />
          </div>
          <div className='card p-20'>
            <h4 className='text-[#383838] text-bold text-[30px]'>Convenience</h4>
            <p className='text-sm lg:text-[#475569] mt-10'>No need for complex setup or technical knowledge. Our platform is designed to be user-friendly, making it easy for anyone to send encrypted messages and files.</p>
            <img src="./public/images/features/convience.svg" width="100%" alt="" />
          </div>
        </div>

        <div className='card p-20 mt-100'>
          <h1 className="text-[30px] lg:text-[40px] text-bold text-center leading-[48px]">
            <span className='text-[#014CDA]'>Get Started</span> Today!
          </h1>
          <p className='text-sm lg:text-[#475569] mt-10 text-center'>Protect your communications with our Secure Message service. Sign up now to start sending encrypted messages and file attachments with confidence. Experience the peace of mind that comes with knowing your information is <span className='font-[700] italic'>secure</span> and  <span className='font-[700] italic'>private</span>.</p>
          <p className='text-sm lg:text-[#475569] mt-10 text-center'>
            Sign up for a <span className='font-[700] italic'>FREE Basic Account</span> and start using Secure Message right away!
          </p>
          <Link to='/register' className='m-auto'>
            <button className='btn-login w-[150px]  mt-20'>Sign Up Now</button>
          </Link>
        </div>
      </div></>
  )
}

function AnywhereAccess() {
  return (
    <>
      <div className="grid md:grid-cols-2 gap-5 pt-50">
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <p className='text-sm text-[#F67A3C]'>Your Documents, Available Whenever and Wherever You Need Them</p>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
            Access Your Files <span className='text-[#014CDA]'>Anywhere, Anytime</span>
          </h1>
          <p className='text-sm lg:text-[#475569] mt-10'>With MyDocUploads, your important documents are always at your fingertips. Whether you're at the office, at home, or on the go, our platform ensures you can access your files from any device, 24/7. Say goodbye to the hassle of physical storage and enjoy the convenience of cloud-based document management.</p>
        </div>
        <div>
          <img src="./public/images/features/anywhere-access.svg" width="100%" alt="" />
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-5 lg:pt-50 item-center justify-center">
        <div>
          <img src="./public/images/features/key-features.svg" width="100%" className='mt-20 md:mt-0' alt="" />
        </div>
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
            Benefits of   <span className='text-[#014CDA]'>Anywhere, Anytime Access</span>
          </h1>
          <ul className='mt-5'>
            <li className='mt-10'><span className='text-[#F67A3C] font-bold'>Flexibility:</span> Work from any location without being tied to a single device or physical files.</li>
            <li className='mt-10'><span className='text-[#F67A3C] font-bold'>Productivity:</span> Quickly find and retrieve the documents you need, reducing downtime and increasing efficiency.</li>
            <li className='mt-10'><span className='text-[#F67A3C] font-bold'>Backup:</span> Your files are stored securely in the cloud, providing automatic backup and easy recovery in case of device loss or failure.</li>
          </ul>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-5 pt-50 item-center justify-center">
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
          How <span className='text-[#014CDA]'> It Works?</span>
          </h1>
          <p className='text-sm lg:text-[#475569] mt-10'>MyDocUploads uses cutting-edge cloud technology to store your documents securely online. Simply upload your files to our platform, and access them using our web interface or mobile app. Whether you need to review a contract, share a report, or work on a project, MyDocUploads makes it easy to stay connected with your documents.
          </p>
        </div>
        <div>
          <img src="./public/images/features/how-work.svg" width="100%" className='mt-10' alt="" />
        </div>
      </div>
    </>
  )
}

function SecureCollaboration() {
  return (
    <>
      <div className="grid md:grid-cols-2 gap-5 pt-50">
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <p className='text-sm text-[#F67A3C]'>Seamless Collaboration and Secure File Sharing</p>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
            <span className='text-[#014CDA]'>Share and Collaborate Securely </span> with Anyone
          </h1>
          <p className='text-sm lg:text-[#475569] mt-10'>Enhance your team's productivity with MyDocUploads. Share files securely with colleagues, clients, and partners. Our platform supports real-time collaboration, making it easy to work together, provide feedback, and achieve your goals faster. Experience the ease of secure file sharing without compromising on data protection.</p>
        </div>
        <div>
          <img src="./public/images/features/secure-collaboration.svg" width="100%" alt="" />
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-5 lg:pt-50 item-center justify-center">
        <div>
          <img src="./public/images/features/key-features.svg" width="100%" className='mt-20 md:mt-0' alt="" />
        </div>
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
            <span className='text-[#014CDA]'>Key Features </span> of Secure Collaboration
          </h1>
          <ul className='mt-5'>
            <li className='mt-10'><span className='text-[#F67A3C] font-bold'>Real-Time Editing:</span> Collaborate on documents in real-time, ensuring everyone is on the same page.</li>
            <li className='mt-10'><span className='text-[#F67A3C] font-bold'>Version Control:</span> Track changes and access previous versions to avoid conflicts and maintain document integrity.</li>
            <li className='mt-10'><span className='text-[#F67A3C] font-bold'>Access Controls:</span> Set permissions and control who can view, edit, or share your files to ensure data security.</li>
          </ul>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-5 pt-50 item-center justify-center">
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
            Benefits of   <span className='text-[#014CDA]'> Collaboration Made Easy</span>
          </h1>
          <p className='text-sm lg:text-[#475569] mt-10'>MyDocUploads integrates seamlessly with popular productivity tools like Microsoft Office and Google Workspace, allowing you to edit documents directly from our platform. Share files via secure links or invite collaborators via email. Our intuitive interface ensures everyone can collaborate effectively, regardless of their technical expertise.
          </p>
        </div>
        <div>
          <img src="./public/images/features/benefits.svg" width="100%" className='mt-10' alt="" />
        </div>
      </div>
    </>
  )
}

function ProfessionalFileTransfers() {
  return (
    <>
      <div className="grid md:grid-cols-2 gap-5 pt-50">
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <p className='text-sm text-[#F67A3C]'>Professional File Transfers Made Simple</p>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
            Send and Receive Files
            <span className='text-[#014CDA]'>Professionally</span>
          </h1>
          <p className='text-sm lg:text-[#475569] mt-10'>Elevate your business communication with MyDocUploads. Send and receive large files with confidence, knowing your data is secure and the process is streamlined. Our professional-grade file transfer service ensures your documents reach their destination safely and efficiently, reflecting the professionalism of your brand.</p>
        </div>
        <div>
          <img src="./public/images/features/professional-file-transfers.svg" width="100%" alt="" />
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-5 lg:pt-50 item-center justify-center">
        <div>
          <img src="./public/images/features/key-features.svg" width="100%" className='mt-20 md:mt-0' alt="" />
        </div>
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
            <span className='text-[#014CDA]'>Benefits </span> of Professional File Transfers
          </h1>
          <ul className='mt-5'>
            <li className='mt-10'><span className='text-[#F67A3C] font-bold'>Large File Support: </span> Easily send and receive files of any size, avoiding email attachment limits.</li>
            <li className='mt-10'><span className='text-[#F67A3C] font-bold'>Secure Transmission: </span> Our platform uses encryption to protect your files during transfer, ensuring they remain confidential.</li>
            <li className='mt-10'><span className='text-[#F67A3C] font-bold'>Tracking and Notifications: </span> Receive notifications when files are delivered and accessed, giving you complete visibility and control.</li>
          </ul>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-5 pt-50 item-center justify-center">
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
          How   <span className='text-[#014CDA]'>It Works?</span>
          </h1>
          <p className='text-sm lg:text-[#475569] mt-10'>Sending files with MyDocUploads is simple and secure. Upload your file, enter the recipient's email address, and click send. The recipient will receive a secure link to download the file. For added security, you can set expiration dates and password-protect your links. Whether you're sending a large presentation or a confidential report, MyDocUploads ensures your files are delivered safely and professionally.
          </p>
        </div>
        <div>
          <img src="./public/images/features/how-work.svg" width="100%" className='mt-10' alt="" />
        </div>
      </div>
    </>
  )
}

function SecurityCompliance() {
  return (
    <>
      <div className="grid md:grid-cols-2 gap-5 pt-50">
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <p className='text-sm text-[#F67A3C]'>Advanced Security Features to Protect Your Data</p>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
            Security & Compliance Built for
            <span className='text-[#014CDA]'>Peace of Mind</span>
          </h1>
          <p className='text-sm lg:text-[#475569] mt-10'>At MyDocUploads, we prioritize your data's security and compliance. Our platform is designed with state-of-the-art encryption, regular security audits, and strict compliance with industry standards. Rest easy knowing your sensitive information is protected against unauthorized access and breaches.</p>
        </div>
        <div>
          <img src="./public/images/features/security-compliance.svg" width="100%" alt="" />
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-5 lg:pt-50 item-center justify-center">
        <div>
          <img src="./public/images/features/key-features.svg" width="100%" className='mt-20 md:mt-0' alt="" />
        </div>
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
            Comprehensive  <span className='text-[#014CDA]'>Security Measures </span>
          </h1>
          <ul className='mt-5'>
            <li className='mt-10'><span className='text-[#F67A3C] font-bold'>Encryption: </span> All data is encrypted both in transit and at rest, ensuring it remains secure.</li>
            <li className='mt-10'><span className='text-[#F67A3C] font-bold'>Access Controls: </span> Manage user permissions to control who can access and edit your documents.</li>
            <li className='mt-10'><span className='text-[#F67A3C] font-bold'>Audit Trails: </span> Maintain detailed logs of all file access and activity for compliance and security purposes.</li>
          </ul>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-5 pt-50 item-center justify-center">
        <div className='flex flex-col item-center justify-center lg:px-50'>
          <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
            Compliance You  <span className='text-[#014CDA]'>Can Trust</span>
          </h1>
          <p className='text-sm lg:text-[#475569] mt-10'>MyDocUploads complies with industry-leading standards and regulations, including GDPR, HIPAA, and SOC 2. Our commitment to compliance ensures that your data is handled with the utmost care and in accordance with all relevant laws. Trust MyDocUploads to provide a secure environment for your most sensitive documents.
          </p>
        </div>
        <div>
          <img src="./public/images/features/compliance.svg" width="100%" className='mt-10' alt="" />
        </div>
      </div>
    </>
  )
}
