import { Navbar } from '@common/ui/navigation/navbar/navbar';
import NewFooter from '@common/ui/new-footer/NewFooter';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const SecureMessageLink = () => {
  // const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  // console.log('id--->', id);

  const [message, setMessage] = useState<any>(null);
  // Fetch message from the API endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://mydocuploads.com/api/get/message/${id}`, {

        });
        console.log('response', response);

        setMessage(response.data); // Set the fetched message to state
      } catch (error) {
        console.error('Error fetching message:', error);
        // const errorMessage = (error as { response?: { data?: { error?: string } } })?.response?.data?.error;
        // if (errorMessage !== undefined) {
        //   toast.error(`${errorMessage}`);
        // }
      }
    };

    fetchData(); // Call the fetch function
  }, [id]);

  const extractFilename = (url: string) => {
    return url.split('/').pop();
  };
  return (
    <>
      {/* <div className='container mx-auto p-4'>
        <div className="flex justify-center">
          <div className="w-full max-w-3xl">
            <div className="p-4 bg-img" >
              <h1 className="heading">Shared Link</h1>
              <h1 className="heading">Messages Easily</h1>
              <div className='text-area'>
                <p>Secret is saved! Here is the link for your Secret Message.</p>
                <div className='bg-[#90c8f9] p-4 rounded'>
                  <p>sasasasaas</p>
                </div>
                <div className='flex gap-[10px]'>
                  <div className='bg-[#7e7f9b] rounded-full h-[30px] w-[30px] flex justify-center align-center'>
                    <FaLink className='text-white mt-5' />
                  </div>
                  <WhatsappIcon size={32} round={true} />
                  <LinkedinIcon size={32} round={true} />
                  <TwitterIcon size={32} round={true} />


                </div>
              </div>

            </div>
          </div>
        </div>
      </div> */}
      <>
        {!message &&
          <>
            <ToastContainer />
          </>
        }

        <Navbar
          color="transparent"
          className="flex-shrink-0"
          menuPosition="homepage-navbar"
        />
        {!message &&
          <>
            <center className='my-50 py-50'>
              <h1>
                Message View time completed
              </h1>
            </center>
          </>
        }
        {message &&
          <div className='container mx-auto p-4 my-50'>
            <div className="flex justify-center">
              <div className="w-full max-w-3xl">
                <div className="p-4 bg-img" >
                  <h1 className='heading'>Here is your Secret Message:</h1>
                  {/* <div className='text-area h-[200px]'> */}
                    <textarea className="form-control text-area mt-3"  rows={8} readOnly>{message?.data?.secret}</textarea>
                  {/* </div> */}
                </div>
                <p className='expire-text mt-5'>Only {message?.data?.remaninig_view} views are remaning and its expire after {message?.data?.remaning_hours} </p>
                <h2 className='mt-10'>Attached Secret Files:</h2>
                <div className='mt-10'>
                  {message?.data?.file1 &&
                    <div className='flex gap-[10px] items-center mt-10'>
                      {/* <p className='text-muted expire-text text-sm' >Download File 1</p> */}
                      <a className='download-file-btn' href={message?.data?.file1} download={extractFilename(message?.data?.file1)}>Download File 1</a>
                    </div>
                  }
                  {message?.data?.file2 &&
                    <div className='flex gap-[10px] items-center mt-10'>
                      {/* <p className='text-muted expire-text text-sm'>Download File 2</p> */}
                      <a className='download-file-btn' href={message?.data?.file2} download={extractFilename(message?.data?.file2)}>Download File 2</a>
                    </div>
                  }
                  {message?.data?.file3 &&
                    <div className='flex gap-[10px] items-center mt-10'>
                      {/* <p className='text-muted expire-text text-sm'>Download File 2</p> */}
                      <a className='download-file-btn' href={message?.data?.file3} download={extractFilename(message?.data?.file3)}>Download File 3</a>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>}
        <NewFooter />
      </>
    </>
  )
}

export default SecureMessageLink
