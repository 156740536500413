import { Navbar } from '@common/ui/navigation/navbar/navbar'
import NewFooter from '@common/ui/new-footer/NewFooter'
import React from 'react'

const AboutUs = () => {
  return (
    <>
      <div className='container mx-auto'>
        <Navbar
          color="transparent"
          className="flex-shrink-0"
          menuPosition="homepage-navbar"
        />
        <About />
      </div>
      <NewFooter />
    </>
  )
}

export default AboutUs

function About() {
  return (
    <section className="lg:py-[100px] ">
      <div className="container mx-auto">
        <div className="flex flex-col">
          <h1 className='lg:text-7xl md:text-[40px] font-[700] text-center'>Leading the Way in <span className='text-[#014CDA]'>Secure Document</span>Management</h1>
          <p className='text-sm lg:text-[#475569] my-20 text-center'>MyDocUploads is dedicated to providing top-notch document management solutions that combine ease of use with robust security features. Our mission is to help businesses and individuals streamline their document workflows, ensuring accessibility, collaboration, and data protection at all times.</p>

          <img src="./public/images/about-us/about-us.svg" width="100%" alt="" />
        </div>


        <div className="grid md:grid-cols-2 gap-5 lg:pt-50 item-center justify-center">
          <div>
            <img src="./public/images/about-us/story.svg" width="100%" className='mt-20 md:mt-0' alt="" />
          </div>
          <div className='flex flex-col item-center justify-center lg:px-50'>
            <h1 className="lg:text-[40px] text-bold md:leading-[48px]">
              Our  <span className='text-[#014CDA]'> Story</span>
            </h1>
            <p className='text-sm lg:text-[#475569] mt-10'>Founded by a team of tech enthusiasts with a passion for innovation, MyDocUploads has grown into a trusted platform used by professionals and businesses worldwide. We understand the challenges of modern document management and are committed to delivering solutions that meet the highest standards of quality and security.</p>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-5 pt-50">
          <div className='flex flex-col item-center justify-center lg:px-50'>
            <h1 className="lg:text-[40px] text-bold md:leading-[48px]">Our  <span className='text-[#014CDA]'>Vision</span></h1>
            <p className='text-sm lg:text-[#475569] mt-10'>We envision a world where document management is effortless, secure, and accessible to everyone. By continuously improving our platform and listening to our users' needs, we strive to stay at the forefront of the industry and provide the best possible experience for our customers.</p>
          </div>
          <div>
            <img src="./public/images/about-us/vision.svg" width="100%" alt="" />
          </div>
        </div>



      </div>


    </section>
  );
}
